<template>
  <!--begin::Leave Listing-->
  <div class="leave-template">
    <PageHeaderCount
      moduleType="leave"
      :dataLoading="dataLoading"
      :statusList="getLeaveStatus"
      allkey="all_leave"
      countkey="leave_status_count"
    ></PageHeaderCount>
    <ListingTemplate :customClass="'leave-listing'">
      <template v-slot:body>
        <ListingHeader>
          <template v-slot:content>
            <template v-if="false">
              <v-col cols="4" class="pt-0">
                <h1
                  class="form-title custom-nowrap-ellipsis margin-auto selected-rows-text"
                >
                  Selected
                </h1>
              </v-col>
              <v-col cols="8" class="pt-0 justify-flex-end d-flex margin-auto">
                <template>
                  <v-menu
                    content-class="custom-menu-list"
                    bottom
                    offset-y
                    :close-on-click="true"
                  >
                    <template v-slot:activator="{ attrs }">
                      <v-btn
                        depressed
                        :disabled="dataLoading"
                        class="mx-2 custom-grey-border custom-bold-button"
                        color="cyan white--text"
                        v-bind="attrs"
                        v-on:click="deleteLeave"
                      >
                        Bulk Delete
                      </v-btn>
                    </template>
                    <v-list>
                      <!--<template v-for="(item, index) in bulkActions">
                        <v-list-item
                          link
                          v-on:click="bulkAction(item.action)"
                          :key="index"
                        >
                          <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                            <v-icon class="icon-default-blue">{{
                              item.icon
                            }}</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title
                            class="font-weight-500 font-size-14"
                            >{{ item.title }}</v-list-item-title
                          >
                        </v-list-item>
                      </template>-->
                    </v-list>
                  </v-menu>
                </template>
                <v-btn
                  depressed
                  :disabled="dataLoading"
                  class="mx-2 custom-grey-border custom-bold-button"
                  color="red lighten-1 white--text"
                  v-on:click="clearSelections"
                >
                  <v-icon dark left>mdi-close</v-icon>Clear Selections
                </v-btn>
              </v-col>
            </template>
            <template>
              <v-col cols="4" class="py-0">
                <h1 class="form-title margin-auto">
                  <v-select
                    style="max-width: 150px !important"
                    :disabled="dataLoading"
                    :items="getLeaveStatus"
                    v-model="status_filter"
                    @change="leaveFilter"
                    hide-details
                    item-color="cyan"
                    class="pt-0 mt-0 listing-status main-listing-status-filter"
                    item-text="text"
                    item-value="value"
                  >
                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.text"
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          v-text="item.description"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-chip
                          :color="item.color"
                          :text-color="item.textcolor"
                        >
                          <template v-if="item.value == 'all'">{{
                            item.all_leave
                          }}</template>
                          <template v-else>{{
                            item.leave_status_count
                          }}</template>
                        </v-chip>
                      </v-list-item-action>
                    </template>
                  </v-select>
                </h1>
              </v-col>
              <v-col cols="8" class="py-0 d-flex margin-auto">
                <v-text-field
                  v-model.trim="filter_search"
                  v-on:click:clear="clearFilter('search')"
                  clearable
                  dense
                  filled
                  flat
                  label="Search"
                  hide-details
                  style="max-width: 300px"
                  solo
                  :loading="pageLoading"
                  :disabled="pageLoading"
                  color="cyan"
                  @keydown.enter="leaveFilter"
                  @keydown.tab="leaveFilter"
                  v-on:change="leaveFilter"
                ></v-text-field>
                <v-menu
                  v-model="datePicker"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      filled
                      flat
                      label="Visit Date Range"
                      hide-details
                      class="ml-2"
                      solo
                      :disabled="pageLoading"
                      clearable
                      prepend-inner-icon="mdi-calendar"
                      v-on:click:clear="clearFilter('dates')"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :value="formattedDate"
                      color="cyan"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    color="cyan"
                    range
                    :disabled="pageLoading"
                    v-on:change="leaveFilter"
                    v-model="dates"
                  ></v-date-picker>
                </v-menu>
                <v-autocomplete
                  :items="userList"
                  v-model="filter_user"
                  dense
                  flat
                  clearable
                  filled
                  label="Users"
                  item-color="cyan"
                  v-on:click:clear="clearFilter('user')"
                  color="cyan"
                  solo
                  :disabled="pageLoading"
                  v-on:change="leaveFilter"
                  item-value="id"
                  item-text="display_name"
                  class="filter-select ml-2"
                />
                <v-autocomplete
                  :items="leaveCategory"
                  v-model="filter_category"
                  dense
                  flat
                  filled
                  clearable
                  label="Category"
                  v-on:click:clear="clearFilter('category')"
                  item-color="cyan"
                  color="cyan"
                  solo
                  :disabled="pageLoading"
                  v-on:change="leaveFilter"
                  item-value="category"
                  item-text="category"
                  class="filter-select ml-2"
                />
                <template>
                  <v-btn
                    depressed
                    :disabled="dataLoading"
                    color="cyan"
                    class="mx-2 custom-bold-button white--text"
                    @click="create_leave_dialog = true"
                  >
                    <v-icon dark left>mdi-plus</v-icon> Create
                  </v-btn>
                </template>
                <template>
                  <v-btn
                    depressed
                    :disabled="dataLoading"
                    color="cyan"
                    class="mx-2 custom-bold-button white--text"
                    @click="leaveListingEvent = !leaveListingEvent"
                  >
                    <v-icon size="20" dark>{{
                      leaveListingEvent ? "mdi-view-list" : "mdi-grid"
                    }}</v-icon>
                  </v-btn>
                </template>
                <v-btn
                  v-if="false"
                  :disabled="dataLoading"
                  class="mx-2 custom-grey-border custom-bold-button"
                  color="cyan white--text"
                  v-on:click="searchDialog = true"
                >
                  <v-icon dark>mdi-filter</v-icon>
                </v-btn>
                <PageTips v-if="false" module="leave"></PageTips>
              </v-col>
            </template>
          </template>
        </ListingHeader>
        <perfect-scrollbar
          :options="{ suppressScrollX: true }"
          class="scroll custom-box-top-inner-shadow"
          style="max-height: 90vh; position: relative"
        >
          <template v-if="leaveListingEvent">
            <LeaveKanban
              @open-dialog="openLeaveDetail($event)"
              @status-dialog="(statusDialog = $event), (visits = [])"
              @assign:visit="visits = $event"
              @status-data="statusdata = $event"
              @leave-details="leaveDetails"
              @status-change="dropData()"
              :status-data="statusdata"
            ></LeaveKanban>
          </template>
          <template v-else>
            <LeaveListing
              @status-dialog="(statusDialog = $event), (visits = [])"
              @status-data="statusdata = $event"
              @leave-details="leaveDetails"
              :autoRefresh="autoRefresh"
            ></LeaveListing>
          </template>
        </perfect-scrollbar>
      </template>
    </ListingTemplate>

    <template v-if="create_leave_dialog">
      <CreateLeave
        :drawer="create_leave_dialog"
        :leaveUpdateData="leaveCreate"
        @close="
          leaveCreate = {};
          create_leave_dialog = false;
        "
      ></CreateLeave>
    </template>
    <Dialog
      v-if="statusDialog"
      :common-dialog="statusDialog"
      :dialog-width="640"
      isClass
    >
      <template v-slot:title>
        <div class="d-flex">Confirmed</div>
      </template>
      <template v-slot:body>
        <v-form v-model="formValid">
          <v-row class="delete-dialog">
            <v-col md="12" class="py-0 my-auto py-2">
              <div class="btx-div m-0" v-if="statusdata == 'approved'">
                <span class="font-weight-600">
                  <template v-if="visits && visits.visitBarcode">
                    <div style="font-size: 19px !important">
                      Assigned Visits :-
                      <span class="font-weight-500 red--text mr-2">{{
                        visits.visitBarcode
                      }}</span>
                    </div>
                    <br />
                  </template>
                  <span style="font-size: 18px !important"> Are you sure </span>
                  <br /><br />
                  You want to Approve this Leave
                  <v-chip outlined label color="green" class="ml-2"
                    ><span class="font-weight-600">{{
                      leaveBarcode
                    }}</span> </v-chip
                  >&nbsp;?
                </span>
              </div>
              <div v-if="statusdata == 'rejected'">
                <span class="font-weight-600">
                  <span style="font-size: 19px !important"> Are you sure </span>
                  <br /><br />
                  You want to Reject this Leave<!--  {{ leaveId.length > 1 ? "these leaves" : "this Leave" }} -->
                  <v-chip outlined label color="red" class="ml-2">
                    <span class="font-weight-600">{{
                      leaveBarcode
                    }}</span> </v-chip
                  >&nbsp; ?
                </span>

                <div class="mt-3" v-if="statusdata == 'rejected'">
                  <label class="input-label required mb-1">Reject Remark</label>
                  <v-textarea
                    auto-grow
                    dense
                    :rules="[validateRules.required(rejected_Remark, 'Remark')]"
                    filled
                    color="cyan"
                    v-model="rejected_Remark"
                    placeholder="Reason for leave"
                    solo
                    flat
                    row-height="15"
                  ></v-textarea>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-form>
      </template>
      <template v-slot:action>
        <v-btn
          class="mx-2 custom-grey-border custom-bold-button white--text"
          color="cyan"
          :disabled="!formValid"
          @click="dropData(visits.visitId)"
          style="min-width: 78px !important"
        >
          <template
            v-if="
              visits &&
              visits.visitId &&
              visits.visitId.length > 0 &&
              statusdata == 'approved'
            "
          >
            Approve & UnAssigned
          </template>
          <template v-else>
            <template v-if="statusdata == 'approved'"> Approve </template>
            <template v-else> Reject </template>
          </template>
        </v-btn>
        <v-btn
          class="mx-2 custom-grey-border custom-bold-button"
          @click="statusDialog = false"
        >
          Close
        </v-btn>
      </template>
    </Dialog>
  </div>
  <!--end::Leave Listing-->
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
//import ListingMixin from "@/core/plugins/listing-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { PATCH, GET, DELETE } from "@/core/services/store/request.module";
import ListingTemplate from "@/view/pages/partials/Listing-Template.vue";
import ListingHeader from "@/view/pages/partials/Listing-Header.vue";
import { LeaveApplyEventBus } from "@/core/lib/leave.from.lib.js";
import { mapGetters } from "vuex";
import {
  GET_LEAVE_STATUS,
  SET_LOADING,
  SET_FILTER,
  RESET_STATE,
} from "@/core/services/store/leave.module";
//import ListingSearchCriteria from "@/view/pages/partials/Listing-Search-Criteria.vue";
//import Dialog from "@/view/pages/partials/Dialog.vue";
import PageTips from "@/view/layout/extras/offcanvas/PageTips.vue";
import Dialog from "@/view/pages/partials/Dialog";
import PageHeaderCount from "@/view/pages/partials/PageHeaderCount.vue";
import ValidationMixin from "@/core/plugins/validation-mixin";

//import DateRangePicker from "@/view/pages/partials/DateRangePicker";
import CreateLeave from "@/view/pages/leave/Create-Or-Update/CreateLeave";
import LeaveKanban from "@/view/pages/leave/LeaveKanban";
import LeaveListing from "@/view/pages/leave/LeaveListing";
export default {
  mixins: [CommonMixin, ValidationMixin],
  name: "leave-list",
  data() {
    return {
      create_leave_dialog: false,
      leaveBarcode: null,
      leaveId: null,
      exportLoading: false,
      statusdata: null,
      dataLoading: false,
      leaveListingEvent: false,
      leaveCreate: null,
      box: false,
      leaveCategory: [],
      userList: [],
      dates: [],
      filter_category: null,
      status_filter: "all",
      filter_user: 0,
      filter_search: null,
      visits: [],
      autoRefresh: false,
      pageLoading: false,
      selectedRows: [],
      formValid: true,
      statusList: [],
      rejected_Remark: null,
      statusDialog: false,
      moreActions: [
        {
          title: "Refresh List",
          action: "refresh_list",
          divider: true,
          icon: "mdi-refresh",
        },
      ],
      rows_selected: [],
    };
  },
  watch: {},
  components: {
    PageHeaderCount,
    PageTips,
    ListingTemplate,
    ListingHeader,
    Dialog,
    CreateLeave,
    LeaveKanban,
    LeaveListing,
  },
  methods: {
    update_selected(param) {
      this.rows_selected = param;
    },
    leaveDetails(event) {
      this.leaveBarcode = event.barcode;
      this.leaveId = [event.leaveId];
    },
    clearFilter(type) {
      if (type == "dates") {
        this.dates = [];
      } else if (type == "category") {
        this.filter_category = null;
      } else if (type == "user") {
        this.filter_user = null;
      } else if (type == "search") {
        this.filter_search = null;
      }
      this.leaveFilter();
    },
    /*  updateLeave(param) {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "leave/" + param,
        })
        .then(({ data }) => {
          _this.create_leave_dialog = true;
          _this.leaveCreate = new Object({
            id: data && data.id ? data.id : 0,
            staff: data && data.staff ? data.staff : null,
            user_data: data && data.user_data ? data.user_data : null,
            category: data && data.category ? data.category : null,
            duration: data && data.duration ? data.duration : 0,
            duration_type: data && data.duration_type ? data.duration_type : 0,
            day_type: data && data.day_type ? data.day_type : 1,
            start_date: data && data.start_date ? data.start_date : null,
            end_date: data && data.end_date ? data.end_date : null,
            reason: data && data.reason ? data.reason : null,
            role: data && data.role ? data.role : null,
          });
        })
        .catch((error) => {
          _this.logError(error);
          _this.goBack();
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    }, */
    clearSelections() {
      this.selectedRows = [];
    },
    bulkAction(action) {
      const _this = this;
      switch (action) {
        case "approved":
          _this.updateLeaveStatus(2);
          break;
        case "rejected":
          _this.updateLeaveStatus(3);
          break;
      }
    },
    updateLeaveStatus(status) {
      const _this = this;
      _this.dataLoading = true;
      _this.$store
        .dispatch(PATCH, {
          url: "leave/status",
          data: { leave: _this.selectedRows, status },
        })
        .then(() => {
          _this.getRows();
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.dataLoading = false;
        });
    },

    dropData(visit) {
      this.autoRefresh = false;
      this.pageLoading = true;
      let payload = {
        visit: visit,
        leaves: this.leaveId,
        status: this.statusdata, // 1: Pending, 2: Approved, 3: Rejected
        reject_remark:
          this.statusdata == "rejected" ? this.reject_remark : null,
      };
      this.$store
        .dispatch(PATCH, {
          url: `leave/${this.leaveId}/${this.statusdata}`,
          data: payload,
        })
        .then(() => {
          this.statusDialog = false;
          this.detailDialog = false;
          this.reject_remark = null;
          this.autoRefresh = true;
          LeaveApplyEventBus.$emit("refresh");
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.pageLoading = false;
        });
    },
    leaveFilter() {
      let _this = this;
      let leaveFilter = {
        daterange: _this.dates,
        search: _this.lodash.toString(_this.filter_search) || null,
        user: _this.lodash.toSafeInteger(_this.filter_user) || null,
        category: _this.lodash.toString(_this.filter_category) || null,
        status: _this.lodash.toString(_this.status_filter) || null,
      };
      _this.$store.dispatch(SET_FILTER, leaveFilter);
    },
    getLeaveDays() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "leave/options",
        })
        .then(({ data }) => {
          _this.userList = data.users;
          _this.leaveCategory = data.leave_type;
          _this.leaveCategory.push({
            category: "UnPaid",
          });
          this.leaveCategory = this.lodash.map(this.leaveCategory, (row) => {
            return { ...row, category: row.category.replace("-", " ") };
          });
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    moreAction(action) {
      const _this = this;
      switch (action) {
        case "import_items":
          break;
        case "export_items":
          _this.LeaveExport();
          break;
        case "export_current_view":
          _this.exportCurrentView("leave");
          break;
        case "refresh_list":
          window.location.reload();
          break;
      }
    },
    deleteLeave() {
      const _this = this;
      _this.$store
        .dispatch(DELETE, {
          url: "leave/" + 4,
        })
        .then(() => {
          _this.getRows();
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    getAllLeaveStatus() {
      const _this = this;
      _this.$store.dispatch(SET_LOADING, true);
      _this.$store
        .dispatch(GET_LEAVE_STATUS, {})
        .catch((error) => {
          console.log({ error });
        })
        .finally(() => {
          _this.$store.dispatch(SET_LOADING, false);
        });
    },
  },

  mounted() {
    this.getAllLeaveStatus();
    this.getLeaveDays();
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Leave",
      },
    ]);
  },
  destroyed() {
    this.$store.dispatch(RESET_STATE);
  },
  computed: {
    ...mapGetters(["getLeaveStatus"]),
  },
};
</script>
