<template>
  <v-container class="pb-0">
    <perfect-scrollbar
      :options="{ suppressScrollX: true }"
      class="scroll custom-box-top-inner-shadow"
    >
      <!-- <pre>{{defaultColDefs}}</pre> -->
      <ListingTable
        :columnCount="columnLength()"
        :dataLoading="pageLoading"
        :rowData="rowData"
      >
        <template v-slot:thead>
          <thead>
            <tr>
              <template v-for="cols in customThead">
                <th
                  v-if="cols.visible"
                  :key="cols.id"
                  class="simple-table-th"
                  :class="{
                    'checkbox-width-limit': cols.checkbox,
                    'pointer-events-none': lodash.isEmpty(rowData),
                  }"
                >
                  <template v-if="cols.checkbox && Number(cols.checkbox) == 1">
                    <v-checkbox
                      dense
                      :disabled="pageLoading"
                      v-on:change="selectAll"
                      v-model="checkbox"
                      color="cyan"
                      class="hide-margin"
                      hide-details
                    ></v-checkbox>
                  </template>
                  <template v-else>
                    <div
                      v-if="cols.sortable"
                      class="simple-table-text sortable"
                      v-on:click="
                        !pageLoading
                          ? updateSorting({
                              field: cols.field,
                              sort: cols.sort,
                            })
                          : 'javascript:void(0)'
                      "
                    >
                      {{ cols.headerName }}
                    </div>
                    <div v-else class="simple-table-text">
                      {{ cols.headerName }}
                    </div>

                    <template v-if="cols.sort">
                      <v-icon
                        class="simple-table-sort"
                        v-if="cols.sort == 'asc'"
                        small
                        right
                        color="cyan"
                        >mdi-sort-ascending</v-icon
                      >
                      <v-icon
                        class="simple-table-sort"
                        v-if="cols.sort == 'desc'"
                        small
                        right
                        color="cyan"
                        >mdi-sort-descending</v-icon
                      >
                    </template>
                  </template>
                </th>
              </template>
            </tr>
          </thead>
        </template>
        <template v-slot:tbody>
          <tbody class="custom-border-bottom">
            <template v-if="!lodash.isEmpty(rowData)">
              <tr
                v-for="(row, index) in rowData"
                :key="index"
                link
                class="customer-listing-row"
              >
                <template v-for="cols in customThead">
                  <td
                    v-if="cols.visible"
                    :key="cols.id"
                    class="font-size-14 font-weight-500"
                    :class="{
                      'simple-table-td': !cols.checkbox,
                      'checkbox-width-limit': cols.checkbox,
                    }"
                  >
                    <template
                      v-if="cols.checkbox && Number(cols.checkbox) == 1"
                    >
                      <v-checkbox
                        dense
                        v-model="selectedRows"
                        v-bind:value="Number(row.id)"
                        v-on:change="unSeletedRow(row.id)"
                        :disabled="row.status == 3"
                        color="cyan"
                        class="hide-margin"
                        hide-details
                      ></v-checkbox>
                    </template>
                    <template v-else-if="cols.field == 'barcode'">
                      <v-chip
                        label
                        dense
                        small
                        color="chip-custom-blue"
                        outlined
                        class="font-weight-600 custom-barcode"
                        >{{ row.barcode }}
                      </v-chip>
                    </template>
                    <template v-else-if="cols.field == 'staff'">
                      <div class="job-listing-customer">
                        <p class="m-0 custom-nowrap-ellipsis-two-line">
                          <b v-if="row && row.staff">{{
                            row.staff.display_name
                          }}</b>
                        </p>
                      </div>
                    </template>

                    <template v-else-if="cols.field == 'category'">
                      <div class="job-listing-customer">
                        <p class="m-0" v-if="row && row.category">
                          <v-chip
                            class="mx-2 custom-status font-weight-600 custom-grey-border text-uppercase justify-center font-10"
                            color="blue darken-1"
                            label
                            small
                            text-color="white"
                          >
                            {{ row.category.replace("-", " ") }}
                          </v-chip>
                        </p>
                      </div>
                    </template>
                    <template v-else-if="cols.field == 'start_date'">
                      <div
                        class="job-listing-customer"
                        v-if="row && row.start_date"
                      >
                        <v-chip
                          label
                          color="green"
                          text-color=""
                          outlined
                          small
                        >
                          {{ formatDate(row.start_date) }}
                        </v-chip>
                      </div>
                      <em v-else class="text-muted"> no start date</em>
                    </template>
                    <template v-else-if="cols.field == 'end_date'">
                      <div
                        class="job-listing-customer"
                        v-if="row && row.end_date"
                      >
                        <v-chip label color="red" text-color="" outlined small>
                          {{ formatDate(row.end_date) }}
                        </v-chip>
                      </div>
                      <em v-else class="text-muted"> no end date</em>
                    </template>
                    <template v-else-if="cols.field == 'half_day'">
                      <template v-if="row && row.duration">
                        <template v-if="row && row.duration_type == 1">
                          <v-chip
                            class="mb-1 mr-1"
                            label
                            :color="row.day_type == 1 ? 'green' : 'red'"
                            outlined
                            text-color=""
                            small
                          >
                            <template v-if="row.day_type == 1">
                              {{ formatDate(row.start_date) }}
                            </template>
                            <template v-else>
                              {{ formatDate(row.end_date) }}
                            </template>
                          </v-chip>
                          <div style="font-size: 12px; font-weight: 700">
                            (First Half)
                          </div>
                        </template>
                        <template v-else>
                          <v-chip
                            class="mb-1 mr-1"
                            label
                            :color="row.day_type == 1 ? 'green' : 'red'"
                            outlined
                            text-color=""
                            small
                          >
                            <template v-if="row.day_type == 1">
                              {{ formatDate(row.start_date) }}
                            </template>
                            <template v-else>
                              {{ formatDate(row.end_date) }}
                            </template>
                          </v-chip>
                          <div style="font-size: 12px; font-weight: 700">
                            (Second Half)
                          </div>
                        </template>
                      </template>
                      <em v-else class="text-muted"> no half day</em>
                    </template>
                    <template v-else-if="cols.field == 'total_leave_days'">
                      <div
                        style="max-width: fit-content"
                        class="mt-1"
                        v-if="row.total_leave_days > 0"
                      >
                        <span class="mb-2">
                          <v-avatar color="green" size="27">
                            <span class="white--text fw-600">
                              {{ row.total_leave_days }}</span
                            >
                          </v-avatar>
                        </span>
                      </div>
                    </template>
                    <template v-else-if="cols.field == 'reason'">
                      <div style="width: 150px" v-if="row && row.reason">
                        {{ row.reason }}
                      </div>
                      <em class="text-muted" v-else> no reason </em>
                    </template>
                    <template v-else-if="cols.field == 'status'">
                      <v-menu
                        :disabled="row.status != 1"
                        offset-y
                        min-width="100px"
                        style="padding: 0px 4px"
                        rounded
                        bottom
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <div v-bind="attrs" v-on="on">
                            <v-chip
                              style="height: 25px"
                              class="ml-1 mb-1 pl-2 pr-1 text-capitalize"
                              :color="`${
                                row.status_text == 'Pending'
                                  ? 'orange'
                                  : row.status_text == 'Approved'
                                  ? 'green'
                                  : 'red'
                              } white--text`"
                              outlined
                              dence
                              text-color=""
                            >
                              {{ row.status_text }}
                              <v-icon style="font-size: 22px"
                                >mdi-menu-down</v-icon
                              >
                            </v-chip>
                          </div>
                        </template>
                        <v-list>
                          <v-list-item
                            class="list-item cursor-pointer p-1"
                            v-for="(item, index) in statusDropdown"
                            :key="index"
                            @click="updateStatus(item.value, row)"
                          >
                            <v-list-item-title class="pl-4">{{
                              item.text
                            }}</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </template>

                    <template v-else-if="cols.field == 'added_at'">
                      <!--  {{ row.added_by?.display_name  }} -->
                      <TableActivity
                        v-if="!lodash.isEmpty(row.added_by)"
                        :data="row"
                      >
                        <template v-slot:display_name>
                          {{ row.added_by?.display_name }}
                        </template>
                        <template v-slot:date_time>
                          <template v-if="getDateCountAll(row.added_at)">{{
                            row.created_at
                          }}</template>
                          <template v-else>{{
                            formatDateTime(row.added_at)
                          }}</template>
                        </template>
                        <template v-slot:format_date_time>
                          {{ formatDate(row.added_at) }}
                          {{ formatDateStartTime(row.added_at) }}
                        </template>
                      </TableActivity>
                    </template>
                  </td>
                </template>
              </tr>
            </template>
            <tr v-else-if="!pageLoading">
              <td :colspan="10" class="py-4">
                <p
                  class="m-0 row-not-found text-center font-weight-500 font-size-16"
                >
                  <img
                    :src="$assetURL('media/error/empty.png')"
                    class="row-not-found-image"
                  />
                  Uhh... There are no visit at the moment.
                </p>
              </td>
            </tr>
          </tbody>
        </template>
      </ListingTable>
    </perfect-scrollbar>
    <ListingFooter
      :dataLoading="pageLoading"
      :showingFrom="showingFrom"
      :showingTo="showingTo"
      :totalRows="totalRows"
      :currentPage="currentPage"
      :totalPages="totalPages"
    ></ListingFooter>
  </v-container>
</template>

<script>
import moment from "moment-timezone";
import { QUERY, POST } from "@/core/services/store/request.module";
import CommonMixin from "@/core/plugins/common-mixin";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import ListingFooter from "@/view/pages/partials/Listing-Footer";
import { PaginationEventBus } from "@/core/lib/pagination.lib";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import TableActivity from "@/view/pages/partials/Table-Activity.vue";
import { mapGetters } from "vuex";
moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  name: "Leave-listing-template",
  mixins: [CommonMixin],
  props: {
    autoRefresh: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    getLeaveFilter: {
      deep: true,
      immediate: true,
      handler(param) {
        this.getLeaves(param);
      },
    },
    /* autoRefresh: {
      deep: true,
      immediate: true,
      handler(param) {
        console.log({param})
        if(param){
          alert(4545)
          this.getLeaves();
        }
      }
    }, */
  },
  components: {
    /*  Barcode, */
    TableActivity,
    ListingFooter,
    ListingTable,
  },
  data() {
    return {
      totalRows: 0,
      rowsOffset: 0,
      currentPage: 1,
      totalPages: 0,
      checkbox: false,
      defaultColDefs: [],
      defaultColShow: [],
      statusDropdown: [
        {
          text: "Approved",
          value: "approved",
          color: "green",
          headercolor: "primary",
          status: "2",
        },
        {
          text: "Rejected",
          value: "rejected",
          color: "red",
          headercolor: "primary",
          status: "3",
        },
      ],
      customThead: [],
      selectedRows: [],
      perPage: 15,
      pageLoading: false,
      statusList: [],
      rowData: [],
      filter_search: null,
      filter_status: null,
      timeout: null,
      timeoutLimit: 500,
    };
  },
  mounted() {
    const _this = this;
    _this.getLeaves();
  },
  methods: {
    columnLength() {
      const defaultColDefs = this.lodash.filter(this.defaultColDefs, {
        visible: true,
      });
      if (defaultColDefs) {
        return defaultColDefs.length;
      }
      return 0;
    },

    formatDateStartTime(row) {
      return moment(row).format("hh:mm A");
    },
    updateVisible() {
      const _this = this;

      for (let index = 0; index < _this.defaultColDefs.length; index++) {
        const element = _this.defaultColDefs[index];
        _this.defaultColDefs[index].visible = 0;
        if (_this.defaultColShow.includes(element.field)) {
          _this.defaultColDefs[index].visible = 1;
        }
      }

      _this.$nextTick(() => {
        _this.$store
          .dispatch(POST, {
            url: "group",
            data: { tables: this.defaultColDefs },
          })
          .then(({ data }) => {
            this.customThead = data;
          });
      });
    },
    routeToDetail(row) {
      if (this.isDeleted) {
        return false;
      }
      this.$router.push(
        this.getDefaultRoute(this.detailRoute, {
          params: { id: row.visit_id },
        })
      );
    },
    updateStatus(status, row) {
      this.$emit("leave-details", { barcode: row.barcode, leaveId: row.id });
      this.$emit("status-data", status);
      this.$emit("status-dialog", true);
    },
    getStatusColor(status) {
      if (status == 1) {
        return "orange darken-1";
      }
      if (status == 2) {
        return "green darken-1";
      }
      if (status == 3) {
        return "red darken-1";
      }
      return null;
    },

    clearFilter(field) {
      const _this = this;
      switch (field) {
        case "search":
          _this.filter_search = null;
          break;
        case "dates":
          _this.dates = [];
          break;
      }
      _this.getLeaves();
    },
    getLeaves(filter) {
      const _this = this;

      if (_this.pageLoading) {
        return false;
      }
      if (_this.lodash.isEmpty(filter.dates) === false) {
        if (moment(filter.dates[0]).isAfter(filter.dates[1])) {
          ErrorEventBus.$emit(
            "update:error",
            InitializeError("End Date must be after Start Date.")
          );
          return false;
        }
      }

      _this.rowData = [];

      _this.pageLoading = true;

      let current_page = _this.currentPage;
      let per_page = _this.perPage;

      _this
        .getLeaveList({ filter, current_page, per_page })
        .then((data) => {
          _this.rowData = data.rows;
          _this.statusList = data.status_list;
          _this.totalPages = Math.ceil(data.total_rows / data.per_page);
          _this.currentPage = data.current_page;
          let thead = data.theads;
          _this.defaultColDefs = data.theads;
          _this.totalRows = data.total_rows;
          _this.customThead = [];
          for (let index = 0; index < thead.length; index++) {
            let element = thead[index];
            if (element.visible == 1) {
              _this.customThead.push(element);
            }
          }
          let t_out = this.lodash.filter(
            thead,
            (row) => Number(row.visible) == 1
          );
          _this.defaultColShow = this.lodash.map(t_out, "field");
          _this.rowsOffset = data.offset;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(async () => {
          _this.pageLoading = false;
        });
    },

    getLeaveList(data) {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(QUERY, {
              url: "leave",
              data,
            })
            .then(({ data }) => {
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        } catch (error) {
          reject(error);
        }
      });
    },
    selectAll() {
      this.selectedRows = [];
      if (this.checkbox) {
        for (let i = 0; i < this.rowData.length; i++) {
          if (this.rowData[i].status != 3) {
            this.selectedRows.push(this.rowData[i].id);
          }
        }
      }
    },
    unSeletedRow(checkbox) {
      console.log(checkbox);
    },
  },
  computed: {
    showingFrom() {
      return this.lodash.isEmpty(this.rowData) === false
        ? this.rowsOffset + 1
        : 0;
    },
    showingTo() {
      let rowData = this.lodash.map(this.rowData, function (row) {
        if (!row.header) return row;
      });

      rowData = this.lodash.without(rowData, undefined);

      return this.rowsOffset + (rowData ? rowData.length : 0);
    },
    ...mapGetters(["getLeaveFilter", "getLeaveStatus"]),
  },

  beforeDestroy() {},
  created() {
    const _this = this;
    PaginationEventBus.$on("update:pagination", (param) => {
      _this.currentPage = param;
      _this.getLeaves();
    });

    PaginationEventBus.$on("update:per-page", (param) => {
      _this.perPage = param;
    });
  },
};
</script>
